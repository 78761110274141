import { get, isEmpty, trim } from 'lodash';

import { titleCase } from '@marketmuse/utilities';
import { tracker } from '../config/instances';

const serializeAddress = ({
  city,
  country,
  firstName,
  lastName,
  postalCode,
  region,
  street1,
  street2,
}) => {
  const address = [
    [firstName, lastName].join(' '),
    street1,
    street2,
    city,
    region,
    postalCode,
    country,
  ]
    .filter(val => !isEmpty(trim(val)))
    .join(', ');

  return address ? address : void 0;
};

const userDataLayer = ({
  user,
  site,
  subscriptions = [],
  recurlyAccount = {},
}) => {
  return {
    id: get(user, 'id'),
    fullName: get(user, 'fullName'),
    firstName: get(user, 'firstName'),
    lastName: get(user, 'lastName'),
    email: get(user, 'email'),
    org: get(user, 'org'),
    tier: titleCase(get(user, 'org.clientTier') || 'None'),
    sfdcContactId: get(user, 'sfdcContactId'),
    site,
    subscriptions,
    address: {
      account: serializeAddress({
        firstName: get(recurlyAccount, 'firstName'),
        lastName: get(recurlyAccount, 'lastName'),
        ...(get(recurlyAccount, 'address') || {}),
      }),
      billing: serializeAddress({
        firstName: get(recurlyAccount, 'billingInfo.firstName'),
        lastName: get(recurlyAccount, 'billingInfo.lastName'),
        ...(get(recurlyAccount, 'billingInfo.address') || {}),
      }),
      shipping: serializeAddress(
        get(recurlyAccount, 'shippingAddresses.0') || {},
      ),
    },
  };
};

export const updateTrackedUser = ({
  user,
  site,
  subscriptions,
  recurlyAccount,
}) => {
  tracker.update(userDataLayer({ user, site, subscriptions, recurlyAccount }));
};

export const initTrackedUser = ({
  user,
  site,
  subscriptions,
  recurlyAccount,
}) => {
  if (get(tracker, 'user.id') !== user?.id) {
    tracker.init(userDataLayer({ user, site, subscriptions, recurlyAccount }));
  } else {
    updateTrackedUser({ user, site, subscriptions, recurlyAccount });
  }
};

export const trackerReset = () => {
  tracker.clear();
};
