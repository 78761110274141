import { get, isEmpty } from 'lodash';

import Agent from '../Agent';

export const isLibReady = () => !!window.qualified && !!window.qualified;

class Qualified extends Agent {
  isReady() {
    return isLibReady();
  }

  canInit() {
    return this.isReady();
  }

  page(pageInfo) {
    if (!isLibReady()) return;
    window.qualified('page');
  }

  // run this manual when chat opens
  setAttributes(user) {
    if (!isLibReady()) return;

    const { fullName, email } = user || {};

    if (isEmpty(fullName) || isEmpty(email)) {
      return;
    }

    window.qualified('identify', {
      name: user.fullName,
      email: user.email,
      mm_sfdc_contact_id: user.sfdcContactId,
      mm_org_id: get(user, 'org.id'),
      mm_org_name: get(user, 'org.name'),
      mm_sfdc_id: get(user, 'org.sfdcAccountId'),
      mm_recurly_code: get(user, 'org.recurlyAccountCode'),
      mm_daily_addresses: (get(user, 'dailyAddresses') || []).join(', '),
      mm_daily_login_count: get(user, 'dailyLoginCount'),
      highest_inventory_level: user.highestInventoryLevel,
      active_inventory_url: get(user, 'site.domain'),
      tier: user.tier,
      address_account: get(user, 'address.account') || '',
      address_billing: get(user, 'address.billing') || '',
      address_shipping: get(user, 'address.shipping') || '',
    });
  }

  init(user) {
    if (!isLibReady()) return;
    this.setAttributes(user);
  }

  initAnonymous() {
    return;
  }

  clear() {
    if (isLibReady()) {
      window.qualified('identify', {
        name: '',
        email: '',
        mm_org_id: '',
        mm_org_name: '',
        mm_sfdc_id: '',
        mm_recurly_code: '',
        highest_inventory_level: '',
        active_inventory_url: '',
      });
    }
  }

  track(data) {
    // Qualified does not track misc events
    return;
  }
}

export const qualifiedSingleton = new Qualified();
export default Qualified;
