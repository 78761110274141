import { get, pick, findIndex, cloneDeep } from 'lodash';
import { UserUpdateDocument as UPDATE_USER_DATA } from '@marketmuse/data-papi';
import { ACTION_TYPES } from '@marketmuse/data-state';

import Toast from '../../components/Toast';
import { updateTrackedUser } from '../../utils/tracker';
import makeRequest from '../../utils/makeRequest';

import {
  updateUserDataState,
  saveOrgData,
} from '../../actions/authenticateActions';

const setUserInOrg = (org, user) => {
  const userIndex = findIndex(org.users, { id: user.id });
  const users = get(org, 'users') || [];
  users[userIndex] = { ...users[userIndex], ...user };
  const nextOrg = {
    ...org,
    users,
  };

  return nextOrg;
};

const userUpdateFields = [
  'defaultApplicationSerpCountry',
  'displayExactDistribution',
  'email',
  'firstName',
  'lastName',
  'onboardingProgress',
  'onboardingStep',
  'orgRole',
  'phone',
  'suspended',
  'title',
];

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();

    if (
      action.type === ACTION_TYPES.UPDATE_USER_DATA &&
      !get(state, 'guestAccess.token')
    ) {
      const fieldKeys = Object.keys(action.fields || {});
      const userInputs = pick(action.fields, userUpdateFields);
      makeRequest({
        type: ACTION_TYPES.UPDATE_USER_DATA,
        mutation: UPDATE_USER_DATA,
        variables: {
          userInput: userInputs,
          userId: action.userId || get(state, 'user.id'),
        },
        keys: [].concat(
          []
            .concat(fieldKeys, action.keys || [])
            .map(k => `${ACTION_TYPES.UPDATE_USER_DATA}-${k}`),
          fieldKeys.map(
            k => `${ACTION_TYPES.UPDATE_USER_DATA}-${k}-${action.userId}`,
          ),
        ),
      }).then(res => {
        const org = get(state, 'user.org') || {};
        const userData = get(res, 'userUpdate') || {};
        const userId = get(res, 'userUpdate.id');

        if (get(res, 'error')) {
          Toast.fire(get(res, 'error') || 'Failed to update user', 'error');
          if (action.callback) action.callback(false);
        } else {
          const isUserInOrg = (get(org, 'users') || []).find(
            u => u.id === userId,
          );
          const nextOrg = setUserInOrg(cloneDeep(org), cloneDeep(userData));
          if (userId === get(state, 'user.id')) {
            updateTrackedUser({
              user: userData,
              site: get(state, 'filter.site'),
              subscriptions: get(state, 'payment.subscriptions'),
              recurlyAccount: get(state, 'payment.account'),
            });

            dispatch(
              updateUserDataState({
                ...get(state, 'user'),
                ...userData,
                org: nextOrg,
              }),
            );
          } else if (isUserInOrg) {
            dispatch(saveOrgData(nextOrg));
          }

          if (action.callback) {
            action.callback(userData);
          }

          if (!action.preventSuccessToast) {
            Toast.fire('Saved');
          }
        }
      });
    }

    return next(action);
  };
