import * as types from '../../config/types';
import { initTrackedUser } from '../../utils/tracker';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === types.SET_USER_IDENTITY) {
      const subscriptions = getState().payment.subscriptions;
      const recurlyAccount = getState().payment.account;
      const user = action.user;
      const site = action.site;

      if (action.track) {
        initTrackedUser({ user, site, subscriptions, recurlyAccount });
      }
    }

    return next(action);
  };
