import React, {
  useEffect,
  useRef,
  useReducer,
  useState,
  useCallback,
} from 'react';
import { isEmpty, isEqual, uniq } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { useDebouncedCallback } from 'use-debounce';

import { DropZoneCSVWrapper } from '../../DropZoneCSVWrapper';
import { PolymorphicComponentProps } from '../../Polymorphic';
import { reducer } from './reducer';
import {
  InputPillListActions,
  InputPillListProps,
  InputPillListState,
} from './types';
import { Pill } from './Pill';
import { serializeInputValue } from './serializeInputValue';

export type InputPillListComponentProps<C extends React.ElementType> =
  PolymorphicComponentProps<C, InputPillListProps>;

type InputPillListComponent = <C extends React.ElementType>(
  props: InputPillListComponentProps<C>,
) => React.ReactElement | null;

export const InputPillList: InputPillListComponent = React.memo(
  <C extends React.ElementType>({
    as,
    enableDropZone,
    id,
    name,
    items,
    inputProps,
    onChange,
    validation,
    renderBefore,
    renderAfter,
    pillIconClassName,
    className,
    inputWrapperClassName,
  }: InputPillListComponentProps<C>) => {
    const ref = useRef(null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [state, dispatch] = useReducer(reducer, {
      inputStatus: { valid: true, reason: '' },
      inputValue: '',
      isKeyReleased: false,
      items: items || [],
    });

    const handleRemoveItem = useCallback(
      index => {
        const nextItems = [...state.items];
        nextItems.splice(index, 1);

        dispatch({
          type: InputPillListActions.SET_ITEMS,
          payload: { items: nextItems },
        });
        onChange(nextItems);
      },
      [state.items, onChange],
    );

    const handleAddItems = useCallback(
      inputValue => {
        const { items, inputStatus } = serializeInputValue({
          validation,
          inputValue,
        });
        const nextItems = uniq([...state.items, ...items]);

        dispatch({
          type: InputPillListActions.SET_DATA,
          payload: {
            ...(inputStatus.valid
              ? {
                  items: nextItems,
                  inputValue: '',
                }
              : {}),
            inputStatus,
          },
        });
        onChange(nextItems);
      },
      [state.items, validation, onChange],
    );

    const onAddPillDebounced = useDebouncedCallback(handleAddItems, 1500);

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter') {
          handleAddItems(state.inputValue);

          onAddPillDebounced.cancel();
          event.preventDefault();
        }

        if (event.key === 'Backspace') {
          handleRemoveItem(Math.max(state.items.length - 1, 0));
        }

        dispatch({
          type: InputPillListActions.ON_KEY_PRESS,
          payload: { isKeyReleased: false },
        });
      },
      [state.items, state.inputValue],
    );

    const onKeyUp = () => {
      dispatch({
        type: InputPillListActions.ON_KEY_PRESS,
        payload: { isKeyReleased: true },
      });
    };

    const inputValueChangeHandler = (inputChange: string) => {
      if (inputChange[inputChange.length - 1] === ',') {
        handleAddItems(inputChange.slice(0, inputChange.length - 1));
      } else {
        const { inputStatus } = serializeInputValue({
          validation,
          inputValue: inputChange,
        });
        dispatch({
          type: InputPillListActions.SET_DATA,
          payload: { inputValue: inputChange, inputStatus },
        });
      }
    };

    useEffect(() => {
      if (items) {
        dispatch({
          type: InputPillListActions.SET_ITEMS,
          payload: { items: items },
        });
      }
    }, [items]);

    const Component = as || 'input';
    const {
      className: inputClassName,
      placeholder,
      ...restInputProps
    } = inputProps || {};

    return (
      <DropZoneCSVWrapper
        enableDropZone={enableDropZone}
        onUpload={(values: string[][]) => {
          handleAddItems(values.flat().join(','));
        }}
      >
        {renderBefore}
        <div
          className={twMerge(
            'w-full',
            'px-2',
            'py-2',
            'border',
            'rounded',
            'border-gray-400',
            'min-h-[40px]',
            isInputFocused ? 'border-blue-400 ring-1 ring-blue-400' : '',
            className,
          )}
          onClick={() => {
            ref.current.focus();
          }}
        >
          <div
            className={twMerge(
              ['flex', 'flex-wrap', 'gap-x-1', 'gap-y-2'],
              inputWrapperClassName,
            )}
          >
            {state.items &&
              state.items?.map((item, index) => {
                return (
                  <Pill
                    key={index}
                    color="blue"
                    shade="light"
                    className={[
                      'py-0',
                      'text-sm',
                      'text-gray-900',
                      'flex',
                      'items-center',
                      'min-h-[22px]',
                      'break-all',
                    ]}
                    iconClassName={pillIconClassName}
                    onRemove={() => handleRemoveItem(index)}
                  >
                    {item}
                  </Pill>
                );
              })}
            <Component
              name={name}
              data-mms--input-pill-list={id}
              className={twMerge(
                'block',
                'flex-grow',
                'focus:border-none',
                'focus:outline-none',
                'focus:ring-transparent',
                'focus:shadow-none',
                'max-h-[22px]',
                'ring-transparent',
                'shadow-none',
                'sm:text-sm',
                !state.inputStatus.valid
                  ? ['border-red-400', 'p-1', 'rounded', 'border']
                  : ['border-none'],
                inputClassName,
              )}
              value={state.inputValue}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onChange={event => {
                const inputValue = event.target.value;
                inputValueChangeHandler(inputValue);
                onAddPillDebounced(inputValue);
              }}
              placeholder={state?.items?.length === 0 ? placeholder : ''}
              type="text"
              {...restInputProps}
              ref={ref}
            />
          </div>
          {!state.inputStatus.valid && !isEmpty(state.inputStatus?.reason) && (
            <div className={'p-2 text-red-500 text-[10px]'}>
              {state.inputStatus?.reason}
            </div>
          )}
        </div>
        {renderAfter}
      </DropZoneCSVWrapper>
    );
  },
);
