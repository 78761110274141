import { createSlice, PayloadAction, original } from '@reduxjs/toolkit';
import { merge } from 'lodash/fp';
import { User } from '@marketmuse/config/types/papi';
import { serializeAccessibleInventories } from '@marketmuse/utilities';
import * as ACTION_TYPES from '../actionTypes';
import { initialState } from './user.initialState';

type UserUpdate = {
  key: keyof User;
  value: User[keyof User];
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateField: (state, action: PayloadAction<UserUpdate>) => {
      const key = action.payload.key;
      const value = action.payload.value;

      // eslint-disable-next-line
      // @ts-ignore
      state[key] = value;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      action => action.type === ACTION_TYPES.ORG_DATA_SAVE,
      (state, action) => {
        const nextSites = serializeAccessibleInventories(action.payload.sites);
        const orgBase = original(state?.org);
        const org = merge(orgBase, {
          ...action.payload,
          sites: merge(orgBase.sites, nextSites),
        });

        return {
          ...state,
          org: {
            ...org,
            users: action.payload.users || org.users,
          },
        };
      },
    );
  },
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
